html {
  scroll-behavior: smooth;
}

.header {
  height: 9vh;
  display: flex;
  flex-direction: row;
}

.title {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
}

.sub-title {
  color: #D84;
  margin-bottom: auto;
  font-size: 14px;
}

.logo {
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

.about {
  width: 80%;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  background-image: url("logo_10.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
}

.text-center {
  text-align: center;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer {
  background-color: #eee;
  padding: 10px;
}

ul {
  list-style: circle;
  margin-bottom: 0px !important;
  padding-left: 25px !important;
}

.card-body {
  padding: 15px !important;
}

.navbar-toggler {
  font-size: 1rem !important;
}

.info-icon {
  color: #D84;
}

.padding-right {
  padding-right: 5px !important;
  cursor: zoom-in;
}

.padding-left {
  padding-left: 5px !important;
  cursor: zoom-in;
}